import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { FilterTradeItemDto, ImportTradeItemListDto, MarkingsDto, TradeItemDto } from "@app/user/marks/marks-store/marks-types";
import { ConfigurationService } from "@core/configuration.service";
import { FilterRequestParams, SearchItemPartyParams } from "@helper/abstraction/filter";
import { Observable } from "rxjs";

export class Epass {
	public get apiUrl(): string { return `${this.config.api.epass}`; }

	public getGoodsList = {
		post$: (filter: FilterTradeItemDto): Observable<TradeItemDto[]> => {
			const url = `${this.apiUrl}/itemToParty/filteredList`;
			const { page, size, ...filterDto } = filter;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			params = params.set("size", size.toString());
			return this.http.post<TradeItemDto[]>(url, filterDto, { params, withCredentials: true });
		}
	};

	public getGoodsListPage = {
		post$: (filter: FilterTradeItemDto): Observable<MarkingsDto> => {
			const url = `${this.apiUrl}/itemToParty/searchItemPage`;
			const { page, size, ...filterDto } = filter;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			params = params.set("size", size.toString());
			return this.http.post<MarkingsDto>(url, filterDto, { params, withCredentials: true });
		}
	};

	public searchGoodsList = {
		get$: (filter: FilterRequestParams): Observable<TradeItemDto[]> => {
			const url = `${this.apiUrl}/itemToParty/search`;
			let params = new HttpParams();
			params = params.set("page", filter.page.toString());
			params = params.set("size", filter.size.toString());
			if (filter.searchText) {
				params = params.set("query", filter.searchText.toString() || "");
			}

			return this.http.get<TradeItemDto[]>(url, { params, withCredentials: true });
		}
	};

	public itemToPartySearchItem = {
		post$: (filter: SearchItemPartyParams): Observable<TradeItemDto[]> => {
			const data: SearchItemPartyParams = {
				gtin: filter.gtin,
				name: filter.name,
				tnvedCode: filter.tnvedCode
			}
			const url = `${this.apiUrl}/${ filter.tabMode && filter.tab === "1" ? "itemToParty/" : ""}searchItem`;
			return this.http.post<TradeItemDto[]>(url, data, { withCredentials: true });
		}
	};

	public synchronize = {
		get$: (code: string): Observable<any> => {
			const url = `${this.apiUrl}/synchronize`;
			let params = new HttpParams();
			params = params.set("gcpCode", code);
			return this.http.get<any>(url, { params, withCredentials: true });
		}
	};

	public createTradeItem = {
		post$: (filter: TradeItemDto): Observable<any> => {
			const url = `${this.apiUrl}/createTradeItem`;
			return this.http.post<any>(url, filter, { withCredentials: true });
		}
	};

	public importTradeItemFromXls = {
		post$: (file: File): Observable<ImportTradeItemListDto> => {
			const url = `${this.apiUrl}/importTradeItemFromXls`;
			const formData: FormData = new FormData();
			formData.append("file", file, file.name);
			return this.http.post<ImportTradeItemListDto>(url, formData, { withCredentials: true });
		}
	};

	public generateGtin = {
		get$: (): Observable<string> => {
			const url = `${this.apiUrl}/generateGtin`;
			return this.http.get<string>(url, { withCredentials: true });
		}
	};

	public import = {
		post$: (gtin: string): Observable<void> => {
			const url = `${this.apiUrl}/itemToParty/import?gtin=${gtin}`;
			return this.http.post<void>(url, { withCredentials: true });
		}
	};

	public deleteGoods = {
		delete$: (gtinsToDelete: string[]): Observable<string[]> => {
			const url = `${this.apiUrl}/itemToParty/delete`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: gtinsToDelete,
				withCredentials: true
			};
			return this.http.delete<string[]>(url, options);
		}
	};

	constructor(
		private config: ConfigurationService,
		private http: HttpClient
	) { }
}
