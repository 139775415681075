import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store, createSelector, select } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { UserErrorsService } from '@app/user/user-core/services/user-errors.service';
import { ValidatorsUtil } from '@helper/validators-util';
import { notNull } from '@helper/operators';
import { TranslationService } from "@core/translation.service";

export type ProxyPopupForm = {
	login: string;
	unp: string;
	checkUnp: boolean;
	password: string;
	reasonForCancellation: string;
};

export type ProxyAction = "ANNULMENT" | "CONFIRM_ANNULMENT" | "DENY_ANNULMENT" | "CLARIFICATION" | "CORRECT" | "EDIT";

@Component({
	selector: 'app-proxy-popup',
	templateUrl: './proxy-popup.component.html',
	styleUrls: ['./proxy-popup.component.scss']
})
export class ProxyPopupComponent implements OnInit {
	public form?: FormGroup;
	public name?: string;
	public action?: ProxyAction;
	public defaultHeader = "Для доступа к контейнеру личного ключа введите пароль";
	public mainStoreName = "user";

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		private userErrorsService: UserErrorsService,
		private store: Store,
		private fb: FormBuilder,
		private translationService: TranslationService,
	) {
		this.config.header = this.config?.data?.header ?? this.defaultHeader;
		this.action = this.config?.data?.action;
		this.config.closable = false;
		this.config.width = "600px";
		this.initForm();
	}

	// @HostListener('window:keydown.enter', ['$event'])
	// public handleKeyDown(): void {
	// 	this.confirm(this.form!);
	// }

	public ngOnInit(): void {
		const selectUser = (appState: any): any => appState[this.mainStoreName];
		const checkUnp = createSelector(selectUser, (state: any): boolean | undefined => state.userProfile?.checkUnp);
		const selectCertificateName = createSelector(selectUser, (state: any): string | undefined => state.userProfile?.certificateName);
		const selectUnp = createSelector(selectUser, (state: any): string | undefined => state.organizationInfo?.unp);

		combineLatest(
			this.store.pipe(select(selectCertificateName)),
			this.store.pipe(select(checkUnp)),
			this.store.pipe(select(selectUnp))
		).pipe(
			notNull(),
			take(1)
		).subscribe(([certificateName, checkUnp, unp]) => {
			this.form?.patchValue({ login: certificateName });
			if (checkUnp) {
				this.form?.patchValue({ checkUnp, unp });
			}
		});
		if (this.name) {
			this.form?.patchValue({ login: this.name });
		}
	}

	public confirm(form: FormGroup): void {
		ValidatorsUtil.triggerValidation(form);
		if (form.invalid) {
			this.userErrorsService.displayErrors(form, undefined, undefined, true);
			return;
		}
		this.ref.close(form.getRawValue());
	}

	public checkErrorName(): string {
		return this.translationService.getTranslation(this.action!);
	}

	private initForm(): void {
		this.form = this.fb.group({
			reasonForCancellation: [this.config.data?.form?.reasonForCancellation ?? null],
			unp: [this.config.data?.form?.unp ?? null],
			checkUnp: [this.config.data?.form?.checkUnp ?? null],
			login: [this.config.data?.form?.login ?? null, [Validators.required]],
			password: [this.config.data?.form?.password ?? null, [Validators.required]],
		});

		if (this.action) {
			this.form?.get("reasonForCancellation")?.setValidators([Validators.required]);
			this.form?.get("reasonForCancellation")?.updateValueAndValidity({ emitEvent: false });
		}
	}

	public close(): void {
		this.ref.close();
	}
}
