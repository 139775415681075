import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Document } from "@helper/abstraction/documents";

@Component({
    selector: 'app-search-document-list',
    templateUrl: './search-document-list.component.html',
    styleUrls: ['./search-document-list.component.scss']
})
export class SearchDocumentListComponent {
    @Input() public data: Document[] = [];
    @Input() public notFound = false;
    @Output() public appSelect: EventEmitter<Document> = new EventEmitter<Document>();

    public onRowSelect(event: any): void {
        this.appSelect.emit(event.data as Document);
    }
}
