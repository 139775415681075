<template>
	<dl>
		<dt>maxOrdersMarksValue</dt>
		<dd i18n>Нельзя заказать более 3 000 кодов маркировки на один GTIN. Измените значение в поле "Количество"</dd>
		<dt>min</dt>
		<dd i18n>При заказе должно быть указано количество кодов маркировки. Введите значение (отличное от 0) в поле</dd>
		<dt>onlyBLRWBL</dt>
		<dd i18n>Реестр для ТН сформировать невозможно. Выберите только ТТН.</dd>
		<dt>isMoreOfToday</dt>
		<dd i18n>должна быть не более текущей даты</dd>
		<dt>isSmallerOfToday</dt>
		<dd i18n>должна быть не раньше текущей даты</dd>
		<dt>onlySender</dt>
		<dd i18n>Реестр может сформировать только грузоотправитель</dd>
		<dt>sentDocumentMessage</dt>
		<dd i18n>Документ обрабатывается. Страница будет обновлена.</dd>
		<dt>storageNotActive</dt>
		<dd i18n>Выбранный Вами склад более недоступен. Страница будет обновлена.</dd>
		<dt>editInvitation</dt>
		<dd i18n>Приглашение успешно отредактировано.</dd>
		<dt>sendInvitation</dt>
		<dd i18n>Приглашение(-я) успешно обработано(-ы).</dd>
		<dt>deleteInvitation</dt>
		<dd i18n>Приглашение успешно удалено.</dd>
		<dt>sendSuccess</dt>
		<dd i18n>Документ успешно отправлен.</dd>
		<dt>documentQueuedSending</dt>
		<dd i18n>Документ поставлен в очередь на отправку.</dd>
		<dt>markAsReadSuccess</dt>
		<dd i18n>Документы помечены как прочитанные.</dd>
		<dt>acceptSuccess</dt>
		<dd i18n>Документ успешно принят.</dd>
		<dt>signSuccess</dt>
		<dd i18n>Документ успешно подписан.</dd>
		<dt>singAndSaveOk</dt>
		<dd>Документ успешно подписан и отправлен.</dd>
		<dt>cancelSuccess</dt>
		<dd>Документ успешно отменен.</dd>
		<dt>cancelDocumentQueuedSend</dt>
		<dd>Отмена на документ поставлена в очередь на отправку.</dd>
		<dt>changeRequiredNotConfirmedCancelError</dt>
		<dd>Накладная не может быть отменена при неподтвержденном требовании изменений.</dd>
		<dt>rejectSuccess</dt>
		<dd>Документ успешно отклонён.</dd>
		<dt>confirmCancel</dt>
		<dd>Отмена документа успешно подписана и отправлена.</dd>
		<dt>confirmReceiptOk</dt>
		<dd>Подтверждение о получении успешно отправлено.</dd>
		<dt>receiptConfirmationQueuedDispatch</dt>
		<dd>Подтверждение о получении поставлено в очередь на отправку.</dd>
		<dt>confirmReceiptCancel</dt>
		<dd>Подтверждение получения отмены успешно отправлено.</dd>
		<dt>confirmReceiptEdit</dt>
		<dd>Подтверждение получения запроса на изменение успешно отправлено.</dd>
		<dt>editSuccess</dt>
		<dd>Изменение документа успешно подписано и отправлено.</dd>
		<dt>changeNotificationQueuedSent</dt>
		<dd>Уведомление об изменении поставлено в очередь на отправку.</dd>
		<dt>editSuccessOk</dt>
		<dd>Изменение успешно отправлено.</dd>
		<dt>checkSignOK</dt>
		<dd i18n>Успешно прошла проверка на целостность и подлинность.</dd>
		<dt>checkSignError</dt>
		<dd i18n>Не прошла проверка на целостность и подлинность.</dd>
		<dt>defaultError</dt>
		<dd i18n>Произошла неизвестная ошибка на стороне сервера.</dd>
		<dt>required</dt>
		<dd i18n>Не заполнено обязательное поле</dd>
		<dt>validationRequired</dt>
		<dd i18n>Заполните все обязательные поля</dd>
		<dt>validationValidity</dt>
		<dd i18n>Данные введены неверно</dd>
		<dt>requiredTrue</dt>
		<dd i18n>Подтвердите, что</dd>
		<dt>registrationError</dt>
		<dd i18n>Ошибка регистрации. Обратитесь к администрации системы.</dd>
		<dt>contractAgree</dt>
		<dd i18n>ознакомились с договором и согласны с условиями</dd>
		<dt>politicsAgree</dt>
		<dd i18n>ознакомились с политикой конфиденциальности</dd>
		<dt>maxlength</dt>
		<dd i18n>Превышена максимальная длина</dd>
		<dt>minlength</dt>
		<dd i18n>Слишком мало символов</dd>
		<dt>notGLN</dt>
		<dd i18n>Не является GLN. Некорректное контрольное число </dd>
		<dt>notEdsProxyPort</dt>
		<dd i18n>Некорректный Порт SC-CryptoProxy. Должно быть меньше 65535</dd>
		<dt>notPositiveEdsProxyPort</dt>
		<dd i18n>Некорректный Порт SC-CryptoProxy. Значение должно быть положительным</dd>
		<dt>email</dt>
		<dd i18n>Некорректный</dd>
		<dt>unavailableSCCryptoProxy</dt>
		<dd>Не удалось подключиться к SC-CryptoProxy</dd>
		<dt>bindProxy</dt>
		<dd>К учетной записи не привязан ни один сертификат ЭЦП. Зайдите в "Мой профиль" и привяжите ЭЦП</dd>
		<dt>bindEdsProxy</dt>
		<dd>Ошибка при подписи. Второе и последующие подписания возможны только с использованием стандартной ЭЦП (не мобильной).</dd>
		<dt>notGTIN</dt>
		<dd i18n>Некорректное контрольное число</dd>
		<dt>userActivationSuccess</dt>
		<dd i18n>Пользователь успешно активирован. Используйте новый пароль для входа в систему</dd>
		<dt>recoveredPasswordSuccess</dt>
		<dd i18n>Пароль успешно изменен. Используйте новый пароль для входа в систему</dd>
		<dt>from</dt>
		<dd i18n>от</dd>
		<dt>lessThan</dt>
		<dd i18n>Дата окончания периода не может быть раньше даты начала периода</dd>
		<dt>lessThanOrEqualTo</dt>
		<dd i18n>Дата окончания периода доставки должна быть позднее даты начала периода доставки</dd>
		<dt>to</dt>
		<dd i18n>до</dd>
		<dt>issued</dt>
		<dd i18n>выданный</dd>
		<dt>certPartyNumber</dt>
		<dd i18n>номер партии</dd>
		<dt>certProductDate</dt>
		<dd i18n>дата производства</dd>
		<dt>checkPeriod</dt>
		<dd i18n>"Дата начала расчетного периода" не может быть больше, чем "Дата окончания расчетного периода"</dd>
		<dt>emailError</dt>
		<dd i18n>Адрес электронной почты указан в неверном формате</dd>
		<dt>createStorageSuccess</dt>
		<dd i18n>Склад успешно добавлен</dd>
		<dt>createStorageError</dt>
		<dd i18n>Склад не может быть добавлен</dd>
		<dt>buyerNotSelected</dt>
		<dd i18n>Покупатель/заказчик не выбран</dd>
		<dt>shipmentBuyerNotSelected</dt>
		<dd i18n>Покупатель не выбран</dd>


		<dt>synchronizeSuccess</dt>
		<dd i18n>Список товаров актуализирован</dd>
		<dt>productsError</dt>
		<dd>Добавьте товары/услуги</dd>
		<dt>productsEcmrError</dt>
		<dd>Добавьте информацию о грузе</dd>
		<dt>ltsError</dt>
		<dd>Отсуствует сертификат для подписи</dd>
		<dt>saveSuccess</dt>
		<dd>Черновик сохранён</dd>
		<dt>invalid</dt>
		<dd>Невозможно подписать невалидную форму</dd>
		<dt>deletionPopupText</dt>
		<dd i18n>Вы действительно хотите удалить черновик электронного акта?</dd>
		<dt>processSignThenSendPopupText</dt>
		<dd i18n>Вы действительно хотите подписать и отправить электронный акт?</dd>
		<dt>ok</dt>
		<dd i18n>Ок</dd>
		<dt>cancel</dt>
		<dd i18n>Отмена</dd>

		<dt>versionUpdated</dt>
		<dd i18n>Версия ПО обновлена.</dd>
		<dt>clearCookiesAndFile</dt>
		<dd i18n>Будут очищены временные файлы и файлы cookie.</dd>
		<dt>validationError</dt>
		<dd i18n>Ошибка валидации.</dd>
		<dt>signingDocError</dt>
		<dd i18n>Документ уже подписан.</dd>
		<dt>signingError</dt>
		<dd i18n>Документ не подписан.</dd>
		<dt>sendingError</dt>
		<dd i18n>Ошибка отправки.</dd>
		<dt>signError</dt>
		<dd i18n>Документ не подписан.</dd>
		<dt i18n>internalError</dt>
		<dd>Внутренняя ошибка.</dd>
		<dt>incorrectEmail</dt>
		<dd i18n>Некорректный Email.</dd>
		<dt>wrongUnp</dt>
		<dd i18n>Сертификат (ЭЦП) не соответствует указанной в документе организации</dd>
		<dt>wrongUnpBind</dt>
		<dd i18n>Сертификат ЭЦП не соответствует УНП организации</dd>

		<dt>massActionsError</dt>
		<dd i18n>Действие недоступно для отправленных документов. Выберите только черновики документов</dd>
		<dt>massDeleteActionsError</dt>
		<dd i18n>Действие доступно только для документов в статусе "Черновик" и "Черновик с ЭЦП"</dd>
		<dt>massSignActionsError</dt>
		<dd i18n>Действие доступно только для неподписанных черновиков. Выберите неподписанный черновик.</dd>
		<dt>massSendActionsError</dt>
		<dd i18n>Действие доступно только для черновиков с ЭЦП. Выберите черновик с ЭЦП.</dd>
		<dt>massActionsDraftError</dt>
		<dd i18n>Действие недоступно для подписанных черновиков. Выберите только черновики без ЭЦП</dd>
		<dt>massHandleActionsError</dt>
		<dd i18n>Действие доступно только для документов в статусе "Получен". Выберите только документ в статусе "Получен"</dd>
		<dt>handleActionError</dt>
		<dd i18n>Обработать документ может только грузополучатель</dd>
		<dt>handleActionConfirmReceiptError</dt>
		<dd i18n>Для выбранных документов (или части из них) недоступно подтверждение получения.</dd>
		<dt>massActionsEwaybillMilkRoleError</dt>
		<dd i18n>У вас нет прав для работы с ТТН-молоко.</dd>
		<dt>massActionsDocumentVersionError</dt>
		<dd i18n>Действие доступно только для документов одной версии.</dd>
		<dt>massActionsDocumentOldVersionError</dt>
		<dd i18n>Обработка документов формата по Постановлению № 12/76/42/20 от 19 декабря 2019 г. запрещена.</dd>


		<dt>massActionsExportNotWblDnrError</dt>
		<dd i18n>Для выбранных документов нельзя сформировать реест. Выберите ТТН (кроме экспортной) и/или ТН (иной документ).</dd>
		<dt>massActionsExportNotExtCountryError</dt>
		<dd i18n>Реестр для ТН данного типа сформировать невозможно. Выберите только межстрановые ТН.</dd>
		<dt>massActionsExportErrorMessage</dt>
		<dd i18n>Реестр для экспортных накладных сформировать невозможно. Выберите другой тип накладных.</dd>
		<dt>massActionsExportExportMessage</dt>
		<dd i18n>Выбранные документы содержат как ТТН, так и ТН (иной документ). Формирование реестра возможно только для документов одного вида.</dd>
		<dt>massActionsExportStorageErrorMessage</dt>
		<dd i18n>Выберите ТН (иные документы) с одним местом отгрузки.</dd>

		<dt>markAsReadActionsError</dt>
		<dd i18n>Действие недоступно для прочитанных документов.</dd>
		<dt>massActionsSentError</dt>
		<dd i18n>Документ уже отправлен.</dd>
		<dt>exportError</dt>
		<dd i18n>Экспорт черновиков документов не возможен. Выберите только отправленные документы</dd>
		<dt>exportSentError</dt>
		<dd>Документы в статусе Отправлен(а) еще находятся в обработке. Экспорт будет доступен после завершения обработки и смены статуса.</dd>
		<dt>dataMarkError</dt>
		<dd>Ошибка Электронного знака:</dd>
		<dt>confirmReceiptError</dt>
		<dd>Для выбранных документов (или части из них) недоступно подтверждение получения</dd>
		<dt>markingCodeReport</dt>
		<dd>Отчет о маркировке направлен в ГИС "Электронный знак"</dd>
		<dt>eInvoiceSendError</dt>
		<dd i18n>Действие недоступно для выбранных документов. Выберите только документы "Черновик с ЭЦП"</dd>
		<dt>validationdSenderGLNError</dt>
		<dd i18n>Ошибка валидации: GLN отправителя в сообщении не совпадает с GLN фактически отправившей организации</dd>
		<dt>emptyAroundDot</dt>
		<dd i18n>Некорректное заполнение поля </dd>
		<dt>getEactError</dt>
		<dd i18n>У грузополучателя не включена возможность работы с актами приемки.</dd>
		<dt>passwordNotCorrect</dt>
		<dd i18n>Пароль неверен.</dd>

		<dt>documentNotOpen</dt>
		<dd i18n>Выбранный документ нелья открыть.</dd>
		
		<!-- EACT -->
		<dt>discrepanciesError</dt>
		<dd i18n>В акте приемки отсутствуют товарные позиции с расхождениями.</dd>
		<dt>buyerRepresentativeError</dt>
		<dd i18n>Не указан представитель грузополучателя.</dd>
		<dt>successButtonName</dt>
		<dd i18n>Подписать</dd>
		<dt>cancelButtonName</dt>
		<dd i18n>Отменить</dd>
		<dt>discrepanciesEactMessage</dt>
		<dd i18n>Товарные позиции без расхождений будут удалены системой из акта приемки.</dd>
		<dt>eactDiscrepanciesHeader</dt>
		<dd i18n>Подписание акта</dd>
		<dt>openEact</dt>
		<dd i18n>Открыть Акт приемки</dd>
		<dt>traceableEactItemsRemoved</dt>
		<dd i18n>Прослеживаемые товары в накладной не будут добавлены в Акт приемки</dd>
		<dt>createEactReport</dt>
		<dd i18n>Отчет о создании Акта приемки</dd>

		<dt>minValueError</dt>
		<dd i18n>В товарной позиции № «POSITION» значение в поле «Цена (Данные о прослеживаемости)» должно быть больше нуля.</dd>

		<dt>devFunctionaly</dt>
		<dd i18n>Данный функционал находится в разработке.</dd>
		<dt>removeDocument</dt>
		<dd i18n>Вы действительно хотите удалить STATUS DT?</dd>
		<dt>removeDocumentDraft</dt>
		<dd i18n>Вы действительно хотите удалить черновик документа?</dd>
		<dt>extraMB</dt>
		<dd i18n>Превышен максимальный размер файлов (10 MB).</dd>
		<dt>receiver</dt>
		<dd i18n>Не выбран Получатель.</dd>
		<dt>file</dt>
		<dd i18n>Не выбран Файл.</dd>
		<dt>notValidGTIN</dt>
		<dd i18n>GTIN должен начинаться на 29.</dd>
		<dt>emptyFile</dt>
		<dd i18n>Не выбран документ.</dd>

		<!-- ROSEU annuled -->
		<dt>ANNULMENT</dt>
		<dd i18n>Причина аннулирования</dd>
		<dt>DENY_ANNULMENT</dt>
		<dd i18n>Причина отказа аннулирования</dd>
		<dt>CLARIFICATION</dt>
		<dd i18n>Причина уточнения</dd>
	</dl>
</template>
