<div class="container">
	<input *ngIf="showSearch" #input type="text" (input)="onInput(input.value)">
</div>
<ul
	*ngIf="fieldName !== 'storages' && documentTypeId !== 'BLRDOC'"
	#ul
	(scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)"
	[class.new-style-ul]="newStyle">
	<li *ngFor="let item of list" (click)="select(item)" [class.field-map]="!!fieldMap?.length">
		<ng-container *ngIf="fieldMap?.length; else defaultListItem">
			<ng-container *ngIf="!item[0]; else fieldView">{{ item[1] }}</ng-container>
			<ng-template #fieldView>
				<span *ngFor="let field of fieldMap">
					{{ item[0] ? item[0][field] : item[1] }}
				</span>
			</ng-template>
		</ng-container>
		<ng-template #defaultListItem>
			{{ item[1] }}
		</ng-template>
	</li>
</ul>
<ul *ngIf="fieldName === 'storages'" #ul (scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)">
	<li class="storage-block" *ngFor="let item of list" (click)="select(item)">
		<span *ngIf="item[0] === null; else storageInfo">{{item[1]}}</span>
		<ng-template #storageInfo>
			<span class="item-name">{{ item[0]?.storageName }}</span>
			<span class="item-address">{{ item[0]?.name || item[0]?.addressFull }}</span>
			<span class="item-gln">{{ item[0]?.gln }}</span>
		</ng-template>

	</li>
</ul>
<ul *ngIf="fieldName !== 'storages' && documentTypeId === 'BLRDOC'" #ul (scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)" [class.new-style-ul]="newStyle">
	<li class="storage-block" *ngFor="let item of list" (click)="select(item)">
		<span class="item-address">{{ item[0]?.name || item[0]?.addressFull }}</span>
		<span class="item-gln">{{ item[0]?.unp }}</span>
	</li>
</ul>
