import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DocSearchParams } from '@app/user/documents/blrdoc/blrdoc';
import { UserBackendService } from '@app/user/user-core/services/user-backend.service';
import { Document } from "@helper/abstraction/documents";

@Component({
	selector: 'app-search-document-popup',
	templateUrl: './search-document-popup.component.html',
	styleUrls: ['./search-document-popup.component.scss']
})
export class SearchDocumentPopupComponent implements OnInit {
	public docSelectBoxOption = {
		getData$: (selectBoxState: DocSearchParams): Observable<DocSearchParams[]> => {
			return this.userBackendService.blrdoc.searchDoc.post$(selectBoxState);
		},
		mapData: (data: Document[]): Document[] => data,
	};

  	constructor(
    	public config: DynamicDialogConfig,
    	public ref: DynamicDialogRef,
    	private userBackendService: UserBackendService,
    ) { }

  	public ngOnInit(): void {
		this.setData();
	}

	public setData(): void {
		this.config.width = "980px";
		this.config.header = "Поиск оригинального документа";
    	this.config.height = "630px";
	}

  	public close(): void {
		this.ref.close();
	}

	public addDoc(doc: Document): void {
		this.ref.close(doc);
	}
}
