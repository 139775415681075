<ng-container *ngIf="data?.length; else emptyTable">
    <div class="data-block">
        <p-table
            [value]="data"
            [scrollable]="true"
            scrollHeight="flex"
            selectionMode="single"
            (onRowSelect)="onRowSelect($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Статус</th>
                        <th>Системный номер</th>
                        <th>Номер документа</th>
                        <th>Дата</th>
                        <th>Отправитель</th>
                        <th>Получатель
                        <th>Наименование файла</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                    <tr [pSelectableRow]="doc">
                        <td>{{ doc?.processingStatus?.name }}</td>
                        <td>{{ doc?.documentNumber }}</td>
                        <td>{{ doc?.edocumentNumber }}</td>
                        <td>{{ doc?.dateTime | date:'dd.MM.yyy' }}</td>
                        <td>{{ doc?.sender }}</td>
                        <td>{{ doc?.receiver }}</td>
                        <td>{{ doc?.fileName }}</td>
                    </tr>
                </ng-template>
        </p-table>
    </div>
</ng-container>
<ng-template #emptyTable>
    <div class="empty-table">
        <span>
            <ng-container *ngIf="!notFound; else notFoundData">
                <ng-container>Для поиска введите системный номер, номер документа или наименование файла</ng-container>
            </ng-container>
            <ng-template #notFoundData>
                По заданным параметрам документ не найден
            </ng-template>
        </span>
    </div>
</ng-template>