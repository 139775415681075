<ng-container *ngIf="documentType !== 'BLRDOC'">
	<div *ngIf="titleText" [class.document-grid]="isDocumentGrid">
		<ng-container [ngSwitch]="isSender">
			<ng-container *ngSwitchCase="true">
				<ng-container *ngIf="!isReadByPartner; then checked; else readByPartner">
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase="false">
				<ng-container *ngIf="isRead; else notRead">
					<img src="static/icons/Incoming.svg" title="{{ titleText }}"/>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</ng-container>

<ng-template #notRead>
	<ng-container *ngIf="!isLoading">
		<img src="static/icons/NotRead.svg" title="{{ titleText }}"/>
	</ng-container>
</ng-template>

<ng-template #checked>
	<ng-container *ngIf="!errorMessage">
		<img src="static/icons/Checked.svg" title="{{ titleText }}"/>
	</ng-container>
	<ng-container *ngIf="errorMessage">
		<img src="static/icons/OutgoingError.svg" title="Исходящий с ошибкой: {{ errorMessage }}" class="outgoing-error"/>
	</ng-container>
</ng-template>

<ng-template #readByPartner>
	<ng-container *ngIf="!isRead; else checked">
		<img src="static/icons/ReadByPartner.svg" title="{{ titleText }}"/>
	</ng-container>
</ng-template>

<div *ngIf="titleText || isLoading" [class.document-grid]="isDocumentGrid">
	<ng-container *ngIf="isLoading">
		<img class="loading" src="static/icons/Loading.svg" title="{{ titleText }}"/>
	</ng-container>
</div>

<div *ngIf="isNewVersionDoc">
	<img class="loading" src="static/icons/Version.svg"/>
</div>

<ng-container *ngIf="documentType === 'BLRDOC'">
	<div *ngIf="titleText" [class.document-grid]="isDocumentGrid">
		<ng-container [ngSwitch]="isSender">
			<ng-container *ngSwitchCase="true">
				<ng-container *ngIf="isReadByPartner && isRead; else isNotReadByPartner">
					<ng-container *ngIf="!errorMessage; else error">
						<img src="static/icons/blrdoc/OutgoingRead.svg" title="{{ titleText }}"/>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase="false">
				<ng-container *ngIf="isReadByPartner && isRead; else isNotReadIncomingByPartner">
					<img src="static/icons/blrdoc/IncomingRead.svg" title="{{ titleText }}"/>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
	<ng-template #error>
		<img src="static/icons/blrdoc/OutgoingError.svg" title="Исходящий с ошибкой: {{ errorMessage }}" class="outgoing-error"/>
	</ng-template>
	<ng-template #isNotReadByPartner>
		<img src="static/icons/blrdoc/OutgoingNotRead.svg" title="{{ titleText }}"/>
	</ng-template>
	<ng-template #isNotReadIncomingByPartner>
		<img src="static/icons/blrdoc/IncomingNotRead.svg" title="{{ titleText }}"/>
	</ng-template>
</ng-container>
