<form *ngIf="form" [formGroup]="form" (ngSubmit)="confirm(form)">
	<div class="main">
		<ng-container *ngIf="!!action">
			<div>
				<b class="required">Введите причину:</b>
				<app-textarea formControlName="reasonForCancellation" maxLength="1000" name="{{ checkErrorName() }}" [rows]="3"></app-textarea>
			</div>
			<span class="default-label">{{ defaultHeader }}</span>
		</ng-container>
		<div>
			<b class="required">Логин:</b>
			<app-textbox formControlName="login" isDisabled=true></app-textbox>
		</div>
		<div>
			<b class="required">Пароль:</b>
			<app-passwordbox formControlName="password" name="Пароль" [noTrimValue]="true"></app-passwordbox>
		</div>
	</div>
	<footer>
		<button class="save" appSkin="action-save bold">{{ !!action ? "Подписать и отправить" : "ОК" }}</button>
		<button type="button" class="save" appSkin="simple no-shadow bold bordered" (click)="close()">Закрыть</button>
	</footer>
</form>
