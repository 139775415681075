import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";

@Component({
	selector: "app-drop-list",
	templateUrl: "./drop-list.component.html",
	styleUrls: ["./drop-list.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropListComponent {
	@Input() public permissions: (null | Observable<boolean>)[] = [];
	@Input() public tabs: [string, string][] = [];
	@Input() public isTriangle = true;
	@Input() public dropdownUp = false;
	@Input() public changeTabValue = false;

	@Output() public clickTab = new EventEmitter<string>();

	public emit(value: string): void {
		this.clickTab.emit(value);
	}
}
