import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { OverlayService } from "@core/overlay.service";
import { DocSearchParams } from '@app/user/documents/blrdoc/blrdoc';
import { UserErrorsService } from '@app/user/user-core/services/user-errors.service';
import { ValidatorsUtil } from "@helper/validators-util";
import { Document } from "@helper/abstraction/documents";

export interface SelectBoxSelfFetchState {
    [key: string]: any;
};

export interface SelfFetchOption<T> {
    getInitialState: () => SelectBoxSelfFetchState;
    nextChunk: (state: SelectBoxSelfFetchState) => SelectBoxSelfFetchState;
    getData$?: (state: SelectBoxSelfFetchState) => Observable<T>;
    mapData?: (data: T) => T[];
}

@Component({
    selector: 'app-search-document-grid',
    templateUrl: './search-document-grid.component.html',
    styleUrls: ['./search-document-grid.component.scss']
})
export class SearchDocumentGridComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @Input("option") public selfFetchOption: SelfFetchOption<any>;
    @Output() public appFormValue: EventEmitter<Document> = new EventEmitter<Document>();
    public data: Document[] = [];
    public form?: FormGroup;
    public notfound = false;
    public isLoading = false;
    public input$$: Subject<DocSearchParams> = new Subject<DocSearchParams>();
    private unsubscribe$$: Subject<void> = new Subject<void>();
    private state$$: BehaviorSubject<SelectBoxSelfFetchState>;
    private stateTracker$$?: Subject<void>;
    private initialState: SelectBoxSelfFetchState = {};
    private defaultOption: SelfFetchOption<any> = {
        getInitialState: (): SelectBoxSelfFetchState => ({ ...this.initialState }),
        nextChunk: (state): SelectBoxSelfFetchState => ({ ...state }),
    };

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public fB: FormBuilder,
        private overlayService: OverlayService,
        private userErrorsService: UserErrorsService,
    ) {
        this.selfFetchOption = { ...this.defaultOption };
        this.state$$ = new BehaviorSubject<SelectBoxSelfFetchState>(this.selfFetchOption.getInitialState());
        this.input$$.pipe(
            debounceTime(250),
            takeUntil(this.unsubscribe$$)
        ).subscribe(value => this.onInput(value));
    }

    @HostListener("keydown", ["$event"]) public onSubmit(target: any): void {
        if (["Enter", "NumpadEnter"].includes(target.code) && !this.isLoading) {
            this.search();
            target.preventDefault();
        }
    }

    public ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.selfFetchOption && simpleChanges.selfFetchOption.currentValue) {
            this.selfFetchOption = { ...this.defaultOption, ...this.selfFetchOption };
        }
    }

    public ngOnInit(): void {
		this.form = this.initForm();

        this.form?.valueChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.checkForm()) {
				this.notfound = false;
			}
		});

        this.setDefaultFormValue();
    }

    public ngAfterViewInit(): void {
		this.unfold();
		this.changeDetectorRef.markForCheck();
	}

    public ngOnDestroy(): void {
		this.cleanStateTracker();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

    public onInput(search?: DocSearchParams): void {
        this.resetData();
        this.cleanStateTracker();
        const newState = { ...this.selfFetchOption.getInitialState(), ...search };
        this.state$$.next(newState);
        this.stateTracker$$ = this.trackState$$();
    }

    public search(): void {
        const formValues = this.form?.getRawValue() as DocSearchParams;
        if (this.form?.invalid) {
            ValidatorsUtil.triggerValidation(this.form);
            this.userErrorsService.displayErrors(this.form, undefined, undefined, true);
            return;
        }

        for (let k in formValues) {
            if (k === "documentID" && (formValues as any)[k]?.trim()) {
                const documentID = ((formValues as any)[k] as string)?.trim();
                formValues[k] = documentID;
            }
            if (k === "edocumentNumber" && (formValues as any)[k]?.trim()) {
                const edocumentNumber = ((formValues as any)[k] as string)?.trim();
                formValues[k] = edocumentNumber;
            }
            if (k === "fileName" && (formValues as any)[k]?.trim()) {
                const fileName = ((formValues as any)[k] as string)?.trim();
                formValues[k] = fileName;
            }
        }

        if (!Object.values(formValues).filter(el => !["", null, undefined].includes(el?.trim())).length) {
            return;
        }
        if (!this.checkForm()) {
            return;
        }

        this.isLoading = true;
        const newState = { ...this.selfFetchOption.getInitialState(), ...formValues };
        this.input$$.next(newState);
    }

    public trackState$$(): Subject<void> {
        const unsubscribe$$ = new Subject<void>();
        this.state$$.pipe(
            filter(() => this.checkForm()),
            tap(() => this.overlayService.openLoadingPopup(true, "REQUEST_PROCESSING")),
            takeUntil(this.unsubscribe$$),
            takeUntil(unsubscribe$$)
        ).subscribe(state => {
            if (!this.selfFetchOption.getData$) {
                throw Error("No getData$ using for enrich date");
            }
            this.selfFetchOption.getData$({ ...state }).pipe(
                take(1),
                map(data => this.selfFetchOption.mapData ? this.selfFetchOption.mapData(data) : data as any),
                takeUntil(this.unsubscribe$$),
                takeUntil(unsubscribe$$)
            ).subscribe(data => {
                if (Array.isArray(data) && !data.length) {
                    unsubscribe$$.next();
                    unsubscribe$$.complete();
                    this.isLoading = false;
                }
                this.appendData(data);
                this.overlayService.openLoadingPopup(false);
                this.isLoading = false;
                this.notfound = this.checkForm() && !!!this.data.length;
                this.changeDetectorRef.detectChanges();
            }, () => this.overlayService.openLoadingPopup(false));
        });
        return unsubscribe$$;
    }

    public clearForm(): void {
		this.setDefaultFormValue();
		this.input$$.next();
	}

    public select(item: Document): void {
        this.collapse();
        this.appFormValue.emit(item);
        this.changeDetectorRef.markForCheck();
    }

    private resetData(): void {
		this.data = [];
	}

    private cleanStateTracker(): void {
		if (this.stateTracker$$) {
			this.stateTracker$$.next();
			this.stateTracker$$.complete();
			delete this.stateTracker$$;
		}
	}

    private appendData(newData: Document[]): void {
        this.data = [...this.data, ...newData];
    }

    private checkForm(): boolean {
		const formValues = this.form?.getRawValue();
		const dataValues = Object.values(formValues).filter(fl => !!fl);
		return !!dataValues.length;
	}

    private initForm(): FormGroup {
		const form = this.fB.group({
			documentID: [null],
            edocumentNumber: [null],
			fileName: [null],
		});
		return form;
	}

    private setDefaultFormValue(): void {
		this.form?.patchValue({
			documentID: null,
			edocumentNumber: null,
			fileName: null,
		}, { emitEvent: false });
        ["documentID", "edocumentNumber", "fileName"].forEach(field => this.form?.get(field)?.updateValueAndValidity({ emitEvent: true }));
	}

    private unfold(): void {
		this.state$$.next(this.selfFetchOption.getInitialState());
		this.stateTracker$$ = this.trackState$$();
	}

    private collapse(): void {
		this.cleanStateTracker();
		this.resetData();
	}
}
