import { Injectable, Inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { DocumentsState } from "@app/user/documents/documents-store/documents.reducer";
import { Store } from "@ngrx/store";
import { DocumentsFilter } from "@app/user/documents/document-filter/document-filter";
import { resetDocumentFilter, resetDocuments } from "@app/user/documents/documents-store/documents.actions";
import { DocumentState, DocumentType } from "@helper/abstraction/documents";

@Injectable()
export class UserRoutingService {
	public isAfterCreate = false;
	public isAfterSign = false;
	private history: string[] = [];
	private isServiceWorking = false;

	constructor(
		private router: Router,
		private store: Store<DocumentsState>,
		@Inject("itemsToStore") public itemsToStore: number,
	) {
		if (!itemsToStore) {
			throw new Error("Please provide \"itemsToStore\" to UserRoutingService");
		}
	}

	public loadRouting(): void {
		if (this.isServiceWorking) {
			throw new Error("Routing service already working");
		}
		this.isServiceWorking = true;
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: any) => {
				if (this.history[this.history.length - 1] === event.urlAfterRedirects.split("?")[0]) {
					this.history[this.history.length - 1] = event.urlAfterRedirects;
					return;
				}
				this.history = [...this.history.splice((this.itemsToStore * -1) + 1), event.urlAfterRedirects];
			});
	}

	public navigateBack(opts?: { force?: boolean; redirectTo?: string }, isProcurment = false): void {
		if (opts?.redirectTo && !isProcurment) {
			this.router.navigateByUrl(opts.redirectTo);
			return;
		}
		let backIndex = 0;
		const reversedHistory = this.history.reverse();
		reversedHistory.forEach((url, index) => {
			if (["all", "waiting_for_processing", "at_partner", "completed"].indexOf(url.split("?")[0].split("/")[4]) !== -1) {
				if (!backIndex)
					backIndex = index + 1;
			}
		});
		if (this.history.length <= 2 || opts?.force || backIndex === 0) {
			// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
			isProcurment ? this.router.navigateByUrl(opts?.redirectTo!) : window.history.go(-1);
			return;
		}

		window.history.go(-backIndex);
	}

	public goListPage(documentType: DocumentType, documentState: DocumentState = "ALL"): void {
		const queryParams = { documentState };
		this.history = [];
		this.router.navigate(["user", "documents", documentType], { queryParams });
	}

	public goBackSkipPrevPage(): void {
		// this.history = [];
		window.history.go(-2);
	}

	public destroy(): void {
		this.isServiceWorking = false;
		this.history = [];
	}

	public resetDocsAndFilters(documentType: DocumentType, notMakeRequest?: boolean): void {
		const filter = new DocumentsFilter({
			documentState: "ALL",
			documentTypeId: documentType,
			processingStatusList: [],
		});
		this.store.dispatch(resetDocuments());
		this.store.dispatch(resetDocumentFilter(filter.documentTypeId, filter, notMakeRequest));
	}
}
