<section>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="search()">
        <app-textbox
            formControlName="documentID"
            placeholder="Поиск по системному номеру"
            maxLength="14"
            name="Системный номер">
        </app-textbox>
        <app-textbox
            formControlName="edocumentNumber"
            placeholder="Поиск по номеру документа"
            maxLength="14"
            name="Номер документа">
        </app-textbox>
        <app-textbox
            formControlName="fileName"
            placeholder="Поиск по наименованию файла"
            maxLength="512"
            name="Наименование файла">
        </app-textbox>
        <button [disabled]="!checkForm()" type="button" appSkin="simple-gray no-shadow bold" (click)="clearForm()">Сбросить</button>
        <button [disabled]="isLoading" type="submit" appSkin="simple-gray no-shadow bold">Поиск</button>
    </form>
    <app-search-document-list
        [data]="data"
        [notFound]="notfound"
        (appSelect)="select($event)">
    </app-search-document-list>
</section>
